<template>
  <CModal id="ChangeStatus" class="ActionModel" :show.sync="Toggle" :centered="true" @update:show="HandleHideModal">
    <CSelect horizontal :label="'付款方式'" :options="PaymentListOptions" v-model="UpdateStatusData['Payment.ID']" :value.sync="UpdateStatusData['Payment.ID']" :placeholder="$t('Global.PleaseSelect')" @change="ChangePayment()" />
    <CSelect horizontal :label="'配送方式'" :options="LogisticsListOptions" v-model="UpdateStatusData['Logistics.ID']" :value.sync="UpdateStatusData['Logistics.ID']" :placeholder="$t('Global.PleaseSelect')" @change="ChangeLogistics()" />
    <CSelect horizontal :label="'付款狀態'" :options="PaymentStatusList" v-model="UpdateStatusData['Status.Payment']" :value.sync="UpdateStatusData['Status.Payment']" :placeholder="$t('Global.PleaseSelect')" />
    <CSelect horizontal :label="'配送狀態'" :options="LogisticsStatusList" v-model="UpdateStatusData['Status.Logistics']" :value.sync="UpdateStatusData['Status.Logistics']" :placeholder="$t('Global.PleaseSelect')" />
    <CSelect horizontal :label="'訂單狀態'" :options="OrderStatusList" v-model="UpdateStatusData['Status.Order']" :value.sync="UpdateStatusData['Status.Order']" :placeholder="$t('Global.PleaseSelect')" />
    <CElementCover v-if="(Submit === true)" :opacity="0.75" class="py-5">
      <CSpinner color="info"/>
    </CElementCover>
    <template #header-wrapper>
      <header class="modal-header border-bottom-0">
        <h5 class="modal-title">{{ $t('Order.ChangeStatus') }}</h5>
        <CButtonClose @click="HandleHideModal()" />
      </header>
    </template>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton @click="HandleHideModal()" color="light">{{ $t('Global.Cancel') }}</CButton>
        <CButton id="CreateLogisticsOrderSubmit" @click="ChangeSubmit()" color="success">
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  name: "ChangeStatus",
  props: {
    Toggle: {
      type: Boolean,
      default: false
    },
    OrderData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      Submit: false,
      Data: {
        Payment: {},
        Logistics: {},
        Invoice: {},
        Status: {},
        MemberInfo: {},
        Variable: {}
      },
      NewPayment: {},
      NewLogistics: {},
      UpdateStatusData: {
        'Payment.ID': {},
        'Logistics.ID': {},
        'Status.Payment': '',
        'Status.Logistics': '',
        'Status.Order': ''
      },
      PaymentList: [],
      PaymentListOptions: [],
      LogisticsList: [],
      LogisticsListOptions: []
    }
  },
  computed: {
    SelectLogisticsType() {
      const SelectLogistics = this.LogisticsList.find(item => item.ID === this.UpdateStatusData['Logistics.ID'])
      if (!SelectLogistics) {
        return ''
      } else {
        return SelectLogistics.Type
      }
    },
    PaymentStatusList() {
      return Object.keys(this.$t('Order.PaymentStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.PaymentStatusList')[status]
        }
      })
    },
    LogisticsStatusList() {
      return Object.keys(this.$t('Order.LogisticsStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.LogisticsStatusList')[status]
        }
      })
    },
    OrderStatusList() {
      return Object.keys(this.$t('Order.OrderStatusList')).map(status => {
        return {
          value: status,
          label: this.$t('Order.OrderStatusList')[status]
        }
      })
    }
  },
  mounted() {
    this.GetPaymentList()
    this.GetShipmentList()
  },
  watch: {
    Toggle (value) {
      if (value) {
        this.Data = this.OrderData
        this.UpdateStatusData = {
          'Payment.ID': this.OrderData.Payment.ID,
          'Logistics.ID': this.OrderData.Logistics.ID,
          'Status.Payment': this.OrderData.Status.Payment,
          'Status.Logistics': this.OrderData.Status.Logistics,
          'Status.Order': this.OrderData.Status.Order
        }
      }
    }
  },
  methods: {
    HandleHideModal () {
      this.$emit('update:Toggle', false)
      this.Data = {
        Payment: {},
        Logistics: {},
        Invoice: {},
        Status: {},
        MemberInfo: {},
        Variable: {}
      }
    },
    GetPaymentList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/payment/list',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.PaymentList = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.PaymentListOptions = this.PaymentList.map((item) => {
          return {
            label: `${item.Name} (${this.$t('Order.PaymentType')}: ${this.$t('Payment.PaymentMethods.' + item.Method)}，${this.$t('Order.PaymentSystem')}: ${this.$t('Payment.PaymentSystem.' + item.System)}，${this.$t('Payment.Fee')}: ${item.Fee || 0})`,
            value: item.ID
          }
        })
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    GetShipmentList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/list',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.LogisticsList = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.LogisticsListOptions = this.LogisticsList.map((item) => {
          return {
            label: `${item.Name} (${this.$t('Order.LogisticsType')}: ${this.$t('Logistics.LogisticsMethods.' + item.Method)}，${this.$t('Order.LogisticsSystem')}: ${this.$t('Logistics.LogisticsSystem.' + item.System)}，${this.$t('Logistics.Fee')}: ${item.Fee}，${this.$t('Logistics.ServiceLocation')}: ${this.$t('Logistics.ServiceLocationOptions.' + item.ServiceLocation)})`,
            value: item.ID
          }
        })
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePayment () {
      const NewPayment = this.PaymentList.find((item) => item.ID === this.UpdateStatusData['Payment.ID'])
      if (NewPayment) {
        this.NewPayment = Object.assign({}, NewPayment)
      }
    },
    ChangeLogistics () {
      const NewLogistics = this.LogisticsList.find((item) => item.ID === this.UpdateStatusData['Logistics.ID'])
      if (NewLogistics) {
        this.NewLogistics = Object.assign({}, NewLogistics)
      }
    },
    ChangeSubmit () {
      const updateData = {
        'Status.Payment': this.UpdateStatusData['Status.Payment'],
        'Status.Logistics': this.UpdateStatusData['Status.Logistics'],
        'Status.Order': this.UpdateStatusData['Status.Order']
      }
      Object.keys(this.UpdateStatusData).forEach((status) => {
        const StatusSplit = status.split('.')[1]
        switch (status.split('.')[0]) {
          case 'Status':
            if (this.UpdateStatusData[status] === this.Data.Status[StatusSplit]) {
              delete this.UpdateStatusData[status]
              delete updateData[status]
            }
            break
          case 'Payment':
            if (this.UpdateStatusData['Payment.ID'] !== this.Data.Payment.ID) {
              updateData.Payment = this.NewPayment
            }
            break
          case 'Logistics':
            if (this.UpdateStatusData['Logistics.ID'] !== this.Data.Logistics.ID) {
              updateData.Logistics = this.NewLogistics
            }
            break
        }
      })
      this.$Progress.start()
      return this.$store.dispatch('InnerRequest', {
        url: '/order/edit',
        method: 'post',
        data: {
          orderNum: this.Data.OrderNum,
          origin: 'Manage',
          updateData
        }
      }).then(() => {
        this.Submit = false
        this.$Progress.finish()
        this.$emit('Success')
        this.HandleHideModal()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Order/OrderStatusChangeSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        this.Submit = false
        this.$Progress.fail()
        this.$emit('Error', error)
        this.HandleHideModal()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Order/OrderChangeFail') + error.Message,
          type: (error.type ? error.type : 'error')
        })
      })
    }
  }
}
</script>

<style lang="scss">
#ChangeStatus {
  label {
    font-weight: bold;
  }
}
</style>
